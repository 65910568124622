import React from "react";
import { InvalidInvite } from "@/features/Authentication/InvalidInvite";
import { Page } from "@/components/Page";

export default function InvalidInvitePage() {
  return (
    <Page variation="presession">
      <InvalidInvite />
    </Page>
  );
}
