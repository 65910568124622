import React from "react";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Divider } from "@jobber/components/Divider";
import { navigate } from "gatsby";
import * as styles from "@/features/Authentication/PresessionCommon/PresessionCommon.module.css";
import { JobberDevCenterLabel } from "../components/JobberDevCenterLabel";

export function InvalidInvite() {
  function handleClick() {
    navigate("/");
  }

  return (
    <div className={styles.common_wrapper}>
      <JobberDevCenterLabel />
      <Content>
        <div className={styles.invalid_invite_header}>
          <Heading level={2}>Uh oh... Unable to join account</Heading>
        </div>
        <Text size="large">
          This invitation link may no longer be valid. Try again later and
          contact your team member if this problem persists.
        </Text>
        <Divider />
        <Button label="Home" size="large" fullWidth onClick={handleClick} />
      </Content>
    </div>
  );
}
